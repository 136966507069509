import { defineStore } from "pinia";

export const useAppStore = defineStore("App", {
  state: () => ({
    openSidebarRegister: false,
    openSidebarAutenticate: false,
    openForgotPasswordDrawer: false,
  }),
  actions: {
    setOpenSidebarRegister(isOpen: boolean) {
      this.openSidebarRegister = isOpen;
    },
    setOpenSidebarAutenticate(isOpen: boolean) {
      this.openSidebarAutenticate = isOpen;
    },
    setOpenForgotPasswordDrawer(isOpen: boolean) {
      this.openForgotPasswordDrawer = isOpen;
    },
  },
});
